@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {
  //-------------------------------------------------------
  //Popup Start--------------------------------------------
  //-------------------------------------------------------
  .popup-holder-bc {
    width: 100vw;
    height: 100%;
    position: fixed;
    z-index: 31;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $bg-color-black-rgba-70;
    background-color: var(--bg-color-black-rgba-70);
    [dir=ltr] & {
      left: 0;
    }
    [dir=right] & {
      right: 0;
    }
    &.hidden {
      display: none;
    }
  }
  .popup-middleware-holder-bc {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
  .popup-middleware-bc {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .popup-inner-bc {
    display: inline-block;
    vertical-align: middle;
    text-align: initial;
    box-shadow: 0 0 5px 0 #000;
    border-radius: $desktop-border-radius-size-3;
    overflow: hidden;
    animation: $default-transition-duration * 1.5 open-popup ease-in-out;
    animation-delay: $default-transition-duration;
    transform: scale(0);
    animation-fill-mode: forwards;
    transform-origin: top;
    background-color: $default-skin-palette-4;
    background-color: var(--skin-palette-4);
  }
  @keyframes open-popup {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  //-------------------------------------------------------
  //Popup End----------------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //Entrance Popup Start-----------------------------------
  //-------------------------------------------------------
  .entrance-popup-bc {
    display: grid;
    display: -ms-grid;
    position: relative;
    //[dir=ltr] & {
    //  padding-left: $desktop-entrance-popup-banner-width;
    //}
    //[dir=rtl] & {
    //  padding-right: $desktop-entrance-popup-banner-width;
    //}
    > .e-p-content-holder-bc {
      min-height: $desktop-entrance-popup-height;
    }
    &.sign-in {
      > .e-p-content-holder-bc {
        width: $desktop-entrance-popup-sign-in-width;
      }
    }
    &.register {
      > .e-p-content-holder-bc {
        width: $desktop-entrance-popup-registration-width;
      }
    }
  }
  .e-p-banner-bc {
    width: $desktop-entrance-popup-banner-width;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: $default-skin-palette-1;
    background-color: var(--skin-palette-1);
    -ms-grid-column: 1;
    [dir=ltr] & {
      left: 0;
      transform: translate3d(100%, 0, 0);
      animation: $default-transition-duration * 1.5 popup-baner-view-ltr ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: $default-transition-duration * 2.4;
      @keyframes popup-baner-view-ltr {
        0% {
          transform: translate3d(100%, 0, 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
    [dir=rtl] & {
      right: 0;
      transform: translate3d(-100%, 0, 0);
      animation: $default-transition-duration * 1.5 popup-baner-view-rtl ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: $default-transition-duration * 2.4;
      @keyframes popup-baner-view-rtl {
        0% {
          transform: translate3d(-100%, 0, 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  .e-p-b-item-bc {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .e-p-b-img-w-bc {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .e-p-b-img-item-bc {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .e-p-b-controls-holder-bc {
    position: absolute;
    bottom: 8px;
    [dir=ltr] & {
      left: 8px;
    }
    [dir=rtl] & {
      right: 8px;
    }
  }
  .e-p-b-control-bc {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: $desktop-border-radius-size-1;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    [dir=ltr] & {
      margin-right: 8px;
    }
    [dir=rtl] & {
      margin-left: 8px;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: $default-skin-palette-13;
      background-color: var(--skin-palette-13);
      transition-property: background-color, opacity;
      transition-duration: $default-transition-duration;
      opacity: 0.3;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
    &.backward {}
    &.forward {}
    &:last-child {
      margin: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
        background-color: $brand-color-1; //$default-skin-palette-15;
        background-color: var(--brand-color-1); //var(--skin-palette-15);
      }
      > .e-p-b-control-icon-bc {}
    }
  }
  .e-p-b-control-icon-bc {
    display: inline-flex;
    vertical-align: top;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-size: 12px;
    text-align: center;
    color: $text-color-white;
    color: var(--text-color-white);
    transition: color $default-transition-duration ease;
    [dir=rtl] & {
      transform: rotate(180deg);
    }
  }
  .e-p-b-social-holder-bc {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    bottom: 8px;
    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }
  .e-p-b-social-item-bc {
    width: 34px;
    height: 34px;
    margin-bottom: 4px;
    background-color: $default-skin-palette-3;
    background-color: var(--skin-palette-3);
    transition: background-color $default-transition-duration ease;
    cursor: pointer;
    [dir=ltr] & {
      border-radius: $desktop-border-radius-size-2 0 0 $desktop-border-radius-size-2;
    }
    [dir=rtl] & {
      border-radius: 0 $desktop-border-radius-size-2 $desktop-border-radius-size-2 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: $hero-color;
      background-color: var(--hero-color);
      > .e-p-b-social-icon-bc {}
    }
  }
  .e-p-b-social-icon-bc {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
    color: $text-color-white;
    color: var(--text-color-white);
    transition: color $default-transition-duration ease;
  }
  .e-p-content-holder-bc {
    height: 100%;
    position: relative;
    box-shadow: 0 2px 7px -2px rgba(#000, 0.7);
    background-color: $default-skin-palette-4;
    background-color: var(--skin-palette-4);
    -ms-grid-column: 2;
    /*&.invalid {
      .e-p-header-bc:before {
        background-color: $status-color-fail;
        background-color: var(--status-color-fail);
      }
      .e-p-section-item-bc {
        &.active {
          > .e-p-section-title-bc:before {
            background-color: $status-color-fail;
            background-color: var(--status-color-fail);
            box-shadow: 0 0 8px 2px $status-color-fail-rgba-5;
            box-shadow: 0 0 8px 2px var(--status-color-fail-rgba-5);
          }
        }
      }
    }*/
  }
  .e-p-close-icon-bc {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $default-skin-palette-12;
    color: var(--skin-palette-12);
    transition: color $default-transition-duration ease;
    cursor: pointer;
    z-index: 10;
    [dir=ltr] & {
      right: 16px;
    }
    [dir=rtl] & {
      left: 16px;
    }
    &:hover {
      color: $status-color-fail;
      color: var(--status-color-fail);
    }
  }
  .e-p-content-bc {
    height: 100%;
    padding-top: 94px;
    padding-bottom: 120px;
    position: relative;
  }
  .e-p-header-bc {
    width: 100%;
    height: 94px;
    position: absolute;
    top: 0;
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
    /*&:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.4;
      transition: background-color $default-transition-duration ease;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }*/
  }
  .e-p-sections-bc {
    width: 100%;
    padding: 0 48px;
    position: absolute;
    bottom: 0;
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      background-color: $default-skin-palette-12;
      background-color: var(--skin-palette-12);
      opacity: 0.3;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
  }
  .e-p-section-item-bc {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    [dir=ltr] & {
      margin-right: 48px;
    }
    [dir=rtl] & {
      margin-left: 48px;
    }
    &:last-child {
      margin: 0;
    }
    &:hover,
    &.active {
      > .e-p-section-title-bc {
        color: $text-color-white;
        color: var(--text-color-white);
      }
    }
    &.active {
      > .e-p-section-title-bc {
        &:before {
          width: 100%;
        }
      }
    }
  }
  .e-p-section-title-bc {
    display: block;
    padding: 6px 0;
    position: relative;
    font-weight: normal;
    font-size: 16px;
    color: $default-text-palette-13;
    color: var(--text-palette-13);
    text-transform: capitalize;
    transition: color $default-transition-duration ease;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      position: absolute;
      bottom: -1px;
      border-radius: $desktop-border-radius-size-1;
      background-color: $hero-color-extended;
      background-color: var(--hero-color-extended);
      box-shadow: inset 0 0 4px 0 $hero-color, 0 0 8px 2px $hero-color-rgba-30;
      box-shadow: inset 0 0 4px 0 var(--hero-color), 0 0 8px 2px var(--hero-color-rgba-30);
      transition: width $default-transition-duration ease;
      [dir=ltr] & {
        left: 50%;
        transform: translateX(-50%);
      }
      [dir=rtl] & {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
  .e-p-body-bc {
    height: 100%;
    padding: 16px 48px 0;
  }
  .e-p-footer-bc {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    padding: 0 34px;
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }
  .e-p-footer-title-bc {
    display: block;
    position: relative;
    padding-bottom: 4px;
    font-weight: normal;
    font-size: 16px;
    color: $default-text-palette-17;
    color: var(--text-palette-17);
    text-transform: capitalize;
    text-align: center;
    line-height: 22px;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: linear-gradient(90deg, $default-skin-palette-2 0%, $hero-color-extended 52.54%, $default-skin-palette-2 100%);
      background: linear-gradient(90deg, var(--skin-palette-2) 0%, var(--hero-color-extended) 52.54%, var(--skin-palette-2) 100%);
    }
  }
  .e-p-footer-payments-bc {
    height: 56px;
    margin-top: 8px;
    text-align: center;
  }
  .e-p-footer-payment-item-bc {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    padding-top: 20px;
    [dir=ltr] & {
      margin-right: 8px;
    }
    [dir=rtl] & {
      margin-left: 8px;
    }
    &:last-child {
      margin: 0;
    }
  }
  .e-p-footer-payment-image-bc {
    display: inline-block;
    vertical-align: middle;
    max-width: 68px;
  }
  .e-p-footer-live-support-holder-bc {
    margin-top: 8px;
    text-align: center;
  }
  .e-p-footer-live-support-bc {
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
    font-size: 10px;
    color: $default-text-palette-12;
    color: var(--text-palette-12);
    text-transform: capitalize;
    transition: color $default-transition-duration ease;
    cursor: pointer;
    &:hover {
      color: $text-color-white;
      color: var(--text-color-white);
    }
  }
  .sg-n-text-row-1-bc{
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: $default-text-palette-13;
    color: var(--text-palette-13);
    margin-bottom: 5px;
  }
  .sg-n-text-row-2-bc{
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    color: $text-color-white;
    color: var(--text-color-white);
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .entrance-popup-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
    }
  }
  //-------------------------------------------------------
  //Entrance Popup End-------------------------------------
  //-------------------------------------------------------
}

//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  //-------------------------------------------------------
  //Popup Start--------------------------------------------
  //-------------------------------------------------------
  .popup-holder-bc {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $bg-color-black-rgba-70;
    background-color: var(--bg-color-black-rgba-70);

    [dir=ltr] & {
      left: 0;
    }
    [dir=right] & {
      right: 0;
    }
    &.hidden {
      display: none;
    }
  }
  .popup-middleware-holder-bc {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
  .popup-middleware-bc {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .popup-inner-bc {
    display: inline-block;
    vertical-align: middle;
    text-align: initial;
    box-shadow: 0 0 5px 0 $bg-color-black-rgba-10;
    width: 100%;
    overflow: hidden;
    animation: $default-transition-duration * 1.5 open-popup ease-in-out;
    animation-delay: $default-transition-duration;
    opacity: 0;
    transform: inherit;
    position: relative;
    animation-fill-mode: forwards;
    transform-origin: top;
    background-color: $default-skin-palette-3;
    background-color: var(--skin-palette-3);

    &.full-screen {
      height: 100%;
      > .e-p-close-icon-bc{
        position: fixed;
      }
    }
  }
  @keyframes open-popup {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: inherit;
      opacity: 1;
    }
  }

  //-------------------------------------------------------
  //Popup End----------------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //Entrance Popup Start-----------------------------------
  //-------------------------------------------------------
  .entrance-popup-bc {
    display: grid;
    display: -ms-grid;
    position: relative;
    > .e-p-content-holder-bc {
      height: 100%;
      //min-height: 480px;
      //min-height: 90vh;
      box-shadow: none;
    }
    &.sign-in {
      > .e-p-content-holder-bc {
        width: 100%;
      }
    }
    &.register {
      > .e-p-content-holder-bc {
        width: 100%;
      }
    }
  }
  .sg-n-text-row-1-bc{
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: $default-text-palette-13;
    color: var(--text-palette-13);
    margin-bottom: 5px;
  }
  .sg-n-text-row-2-bc{
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    color: $text-color-white;
    color: var(--text-color-white);
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .reg-step-title-v-bc{
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: $text-color-white;
    color: var(--text-color-white);
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 18px;
  }
  .e-p-banner-bc {
    width: $desktop-entrance-popup-banner-width;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: $default-skin-palette-1;
    background-color: var(--skin-palette-1);
    -ms-grid-column: 1;
    [dir=ltr] & {
      left: 0;
      transform: translate3d(100%, 0, 0);
      animation: $default-transition-duration * 1.5 popup-baner-view-ltr ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: $default-transition-duration * 2.4;
      @keyframes popup-baner-view-ltr {
        0% {
          transform: translate3d(100%, 0, 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
    [dir=rtl] & {
      right: 0;
      transform: translate3d(-100%, 0, 0);
      animation: $default-transition-duration * 1.5 popup-baner-view-rtl ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: $default-transition-duration * 2.4;
      @keyframes popup-baner-view-rtl {
        0% {
          transform: translate3d(-100%, 0, 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  .e-p-b-item-bc {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .e-p-b-img-w-bc {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .e-p-b-img-item-bc {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .e-p-b-controls-holder-bc {
    position: absolute;
    bottom: 8px;

    [dir=ltr] & {
      left: 8px;
    }
    [dir=rtl] & {
      right: 8px;
    }
  }
  .e-p-b-control-bc {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: $desktop-border-radius-size-1;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    [dir=ltr] & {
      margin-right: 8px;
    }
    [dir=rtl] & {
      margin-left: 8px;
    }
    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: $default-skin-palette-13;
      background-color: var(--skin-palette-13);
      transition-property: background-color, opacity;
      transition-duration: $default-transition-duration;
      opacity: 0.3;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
    &.backward {}
    &.forward {}
    &:last-child {
      margin: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
        background-color: $brand-color-1; //$default-skin-palette-15;
        background-color: var(--brand-color-1); //var(--skin-palette-15);
      }
      > .e-p-b-control-icon-bc {
      }
    }
  }
  .e-p-b-control-icon-bc {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    font-size: 24px;
    color: $text-color-white;
    color: var(--text-color-white);
    transition: color $default-transition-duration ease;
    [dir=rtl] & {
      transform: rotate(180deg);
    }
  }
  .e-p-b-social-holder-bc {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    bottom: 8px;
    [dir=ltr] & {
      right: 0;
    }
    [dir=rtl] & {
      left: 0;
    }
  }
  .e-p-b-social-item-bc {
    width: 34px;
    height: 34px;
    margin-bottom: 4px;
    background-color: $default-skin-palette-3;
    background-color: var(--skin-palette-3);
    transition: background-color $default-transition-duration ease;
    cursor: pointer;
    [dir=ltr] & {
      border-radius: $desktop-border-radius-size-2 0 0 $desktop-border-radius-size-2;
    }
    [dir=rtl] & {
      border-radius: 0 $desktop-border-radius-size-2 $desktop-border-radius-size-2 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: $hero-color;
      background-color: var(--hero-color);
      > .e-p-b-social-icon-bc {}
    }
  }
  .e-p-b-social-icon-bc {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    font-size: 24px;
    text-align: center;
    line-height: 34px;
    color: $text-color-white;
    color: var(--text-color-white);
    transition: color $default-transition-duration ease;
  }
  .e-p-content-holder-bc {
    height: 100%;
    position: relative;
    //box-shadow: 0 2px 7px -2px rgba(#000, 0.7);
    //background-color: $default-skin-palette-4;
    //background-color: var(--skin-palette-4);
    //-ms-grid-column: 2;
    /*&.invalid {
      .e-p-header-bc:before {
        background-color: $status-color-fail;
        background-color: var(--status-color-fail);
      }
      .e-p-section-item-bc {
        &.active {
          > .e-p-section-title-bc:before {
            background-color: $status-color-fail;
            background-color: var(--status-color-fail);
            box-shadow: 0 0 8px 2px $status-color-fail-rgba-5;
            box-shadow: 0 0 8px 2px var(--status-color-fail-rgba-5);
          }
        }
      }
    }*/
    //.form-control-title-bc{
    //  background-color: $default-skin-palette-4;
    //  background-color: var(--skin-palette-4);
    //}
  }
  .e-p-close-icon-bc {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 14px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $default-skin-palette-16;
    color: var(--skin-palette-16);
    transition: color $default-transition-duration ease;
    cursor: pointer;
    z-index: 10;
    [dir=ltr] & {
      right: 15px;
    }
    [dir=rtl] & {
      left: 15px;
    }
    &:hover {
      color: $status-color-fail;
      color: var(--status-color-fail);
    }
  }
  .e-p-content-bc {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 0;
    position: relative;
  }
  .e-p-header-bc {
    width: 100%;
    height: 50px;
    position: fixed;
    background: $default-skin-palette-3;
    background: var(--skin-palette-3);
    border-bottom: 1px solid $default-skin-palette-5;
    border-bottom: 1px solid var(--skin-palette-5);
    top: 0;
    display: grid;
    z-index: 3;
    grid-template-columns: auto 11fr;
    //justify-content: space-evenly;
    [dir=ltr] & {
      left: 0;
      padding-right: 54px;
      padding-left: 15px;
    }
    [dir=rtl] & {
      right: 0;
      padding-left: 54px;
      padding-right: 15px;
    }
    /*&:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.4;
      transition: background-color $default-transition-duration ease;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }*/
  }
  .popup-t-logo-w-bc{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .e-p-sections-bc {
    padding: 0;
    white-space: nowrap;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    /*[dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }*/
    /*&:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      background-color: $default-skin-palette-12;
      background-color: var(--skin-palette-12);
      opacity: 0.3;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }*/
  }
  .e-p-section-item-bc {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    [dir=ltr] & {
      margin-right: 24px;
    }
    [dir=rtl] & {
      margin-left: 24px;
    }
    &:last-child{
      margin: 0;
    }
    &:hover,
    &.active {
      > .e-p-section-title-bc {}
    }
    &.active {
      > .e-p-section-title-bc {
        &:before {
          width: 100%;
        }
      }
    }
  }
  .e-p-section-title-bc {
    display: block;
    padding: 6px 0;
    position: relative;
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    color: $default-text-palette-19;
    color: var(--text-palette-18);
    transition: color $default-transition-duration ease;
    text-decoration: underline;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      position: absolute;
      bottom: -1px;
      border-radius: $desktop-border-radius-size-1;
      background-color: $hero-color-extended;
      background-color: var(--hero-color-extended);
      box-shadow: inset 0 0 4px 0 $hero-color, 0 0 8px 2px $hero-color-rgba-30;
      box-shadow: inset 0 0 4px 0 var(--hero-color), 0 0 8px 2px var(--hero-color-rgba-30);
      transition: width $default-transition-duration ease;
      [dir=ltr] & {
        left: 50%;
        transform: translateX(-50%);
      }
      [dir=rtl] & {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
  .e-p-body-bc {
    height: 100%;
    padding: 16px 15px 0;
  }
  /*.e-p-footer-bc {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    padding: 0 12px;
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }
  .e-p-footer-title-bc {
    display: block;
    position: relative;
    padding-bottom: 4px;
    font-weight: normal;
    font-size: 12px;
    color: $default-text-palette-17;
    color: var(--text-palette-17);
    text-transform: capitalize;
    text-align: center;
    line-height: 22px;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: linear-gradient(90deg, $default-skin-palette-2 0%, $hero-color-extended 52.54%, $default-skin-palette-2 100%);
      background: linear-gradient(90deg, var(--skin-palette-2) 0%, var(--hero-color-extended) 52.54%, var(--skin-palette-2) 100%);
    }
  }
  .e-p-footer-payments-bc {
    height: 56px;
    margin-top: 8px;
    text-align: center;
  }
  .e-p-footer-payment-item-bc {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    padding-top: 20px;
    [dir=ltr] & {
      margin-right: 8px;
    }
    [dir=rtl] & {
      margin-left: 8px;
    }
    &:last-child {
      margin: 0;
    }
  }
  .e-p-footer-payment-image-bc {
    display: inline-block;
    vertical-align: middle;
    max-width: 50px;
  }
  .e-p-footer-live-support-holder-bc {
    margin-top: 8px;
    text-align: center;
  }
  .e-p-footer-live-support-bc {
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
    font-size: 10px;
    color: $default-text-palette-12;
    color: var(--text-palette-12);
    text-transform: capitalize;
    transition: color $default-transition-duration ease;
    cursor: pointer;
    &:hover {
      color: $text-color-white;
      color: var(--text-color-white);
    }
  }*/




  // Registration Final Step Styling

  .congrats-game-tr-button-group {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .congrats-game-tr-button {
    width: 100%;
    height: 36px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px $default-skin-palette-13 inset;
    box-shadow: 0 0 0 1px var(--skin-palette-13) inset;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 16px;
    color: $text-color-white;
    color: var(--text-color-white);
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .congrats-game-tr-main-button {
    background-color: $brand-color-1;
    color: set-color($brand-color-1);
    height: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .congrats-block {
    width: 100%;
    height: 362px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
  }

  .congrats-block-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  .congrats-block-title {
    position: relative;
    font-size: 24px;
    color: $default-text-palette-19;
    color: var(--text-palette-19);
    font-weight: bold;
    padding-bottom: 10px;
  }

  .congrats-block-desc {
    position: relative;
    font-size: 18px;
    color: $default-text-palette-19;
    color: var(--text-palette-19);
  }

  .congrats-parent-block {
    width: 100%;
  }

  .congrats-block-step-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .congrats-reg-final-step-buttons {
    padding-left: 15px;
    padding-right: 15px;
    .congrats-game-tr-button {
      margin-bottom: 20px;
    }

    .congrats-game-tr-main-button {
      width: 100%;
      border: none;
      border-radius: 8px;
    }
  }

  // Registration Final Step Styling




  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .entrance-popup-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
    }
  }
  //-------------------------------------------------------
  //Entrance Popup End-------------------------------------
  //-------------------------------------------------------

}
//Mobile End--------------------------------------------------------------------------------
