@import "./settings";
@if ($hasSportsBook){}
@mixin kind-of-sport-color ($sports-name, $sports-color) {
    .#{$sports-name}-bg{
      background-color: $sports-color;
      color: set-color($sports-color);
    }
    .#{$sports-name}-txt{
      color: $sports-color;
    }
    .hr-sl-item-bc.#{$sports-name}.active{
      background-color: $sports-color;
    }
  .s-ub-nav-icon-v-bc{
    &.bc-s-i-#{$sports-name}{
      color: $sports-color;
    }
  }
}
@include kind-of-sport-color(favoritecompetitions, #ffd700);
@include kind-of-sport-color(AlpineSkiing, #93c6e5);
@include kind-of-sport-color(AustralianFootball, #119a18);
@include kind-of-sport-color(AmericanFootball, #e7685c);
@include kind-of-sport-color(Badminton, #70c2a7);
@include kind-of-sport-color(Baseball, #5caaa7);
@include kind-of-sport-color(Basketball, #fca601);
@include kind-of-sport-color(BeachHandball, #ccbb87);
@include kind-of-sport-color(BeachSoccer, #bfaf7e);
@include kind-of-sport-color(BeachFootball, #bfaf7e);
@include kind-of-sport-color(BeachVolleyball, #d9c68f);
@include kind-of-sport-color(Biathlon, #bedbed);
@include kind-of-sport-color(BallHockey, #168763);
@include kind-of-sport-color(Bandy, #5da167);
@include kind-of-sport-color(Boxing, #0e7acc);
@include kind-of-sport-color(Canoe, #0d72bf);
@include kind-of-sport-color(Chess, #edbd6f);
@include kind-of-sport-color(Cricket, #1bab7d);
@include kind-of-sport-color(Curling, #76bbef);
@include kind-of-sport-color(Cycling, #d9c68f);
@include kind-of-sport-color(Cyclo-Cross, #d9c68f);
@include kind-of-sport-color(Bowls, #2a8c4c);
@include kind-of-sport-color(Darts, #d32939);
@include kind-of-sport-color(Diving, #118be9);
@include kind-of-sport-color(EBasketball, #b04913);
@include kind-of-sport-color(Electronic, #5e55a1);
@include kind-of-sport-color(FigureSkating, #b1cdde);
@include kind-of-sport-color(Floorball, #72cddb);
@include kind-of-sport-color(Formula1, #b8292b);
@include kind-of-sport-color(Futsal, #65b5c2);
@include kind-of-sport-color(Football, #1a7051);
@include kind-of-sport-color(Freestyle, #edbe13);
@include kind-of-sport-color(FreestyleWrestling, #edbe13);
@include kind-of-sport-color(GaelicFootball, #358014);
@include kind-of-sport-color(Golf, #189946);
@include kind-of-sport-color(GrassHockey, #189443);
@include kind-of-sport-color(Gymnastics, #03b5a5);
@include kind-of-sport-color(Handball, #42777f);
@include kind-of-sport-color(HardAthletics, #0b5b99);
@include kind-of-sport-color(Hockey, #168763);
@include kind-of-sport-color(HorseRacing, #6d5e4d);
@include kind-of-sport-color(Hurling, #189946);
@include kind-of-sport-color(IceHockey, #50a2c6);
@include kind-of-sport-color(Judo, #0b5b99);
@include kind-of-sport-color(LeagueOfLegends, #01586c);
@include kind-of-sport-color(Lacross, #168763);
@include kind-of-sport-color(LightAthletics, #0b5b99);
@include kind-of-sport-color(Athletics, #0b5b99);
@include kind-of-sport-color(Luge, #99c4de);
@include kind-of-sport-color(MixedFootball, #f94d4b);
@include kind-of-sport-color(Motorbikes, #8d612e);
@include kind-of-sport-color(Motorsport, #8d612e);
@include kind-of-sport-color(Nascar, #b92f93);
@include kind-of-sport-color(Netball, #4d4d4d);
@include kind-of-sport-color(Pesapallo, #db8e48);
@include kind-of-sport-color(PokerLive, #116b4f);
@include kind-of-sport-color(Poker, #116b4f);
@include kind-of-sport-color(Pool, #a155a0);
@include kind-of-sport-color(Rally, #c28540);
@include kind-of-sport-color(AutoRacing, #ac9afd);
@include kind-of-sport-color(RinkHockey, #e3bb6d);
@include kind-of-sport-color(Rowing, #0f81d9);
@include kind-of-sport-color(Rugby, #799755);
@include kind-of-sport-color(RugbyLeague, #799755);
@include kind-of-sport-color(RugbyUnion, #799755);
@include kind-of-sport-color(RugbySevens, #799755);
@include kind-of-sport-color(Shooting, #d5d345);
@include kind-of-sport-color(Shorttrackspeedskating, #b1cdde);
@include kind-of-sport-color(SkiJump, #c4dae8);
@include kind-of-sport-color(SkiJumping, #c4dae8);
@include kind-of-sport-color(CrossCountrySkiing, #bedbed);
@include kind-of-sport-color(Snooker, #37b764);
@include kind-of-sport-color(Snowboard, #9dd3f5);
@include kind-of-sport-color(Soccer, #1a7051);
@include kind-of-sport-color(VirtualFootball, #8555a1);
@include kind-of-sport-color(CyberFootball, #308231);
@include kind-of-sport-color(Speedway, #aca696);
@include kind-of-sport-color(Squash, #e1b93f);
@include kind-of-sport-color(Sumo, #b0a174);
@include kind-of-sport-color(Surfing, #3598e4);
@include kind-of-sport-color(Swimming, #118be9);
@include kind-of-sport-color(Tablehockey, #168763);
@include kind-of-sport-color(TableTennis, #839d24);
@include kind-of-sport-color(Tennis, #a19e3e);
@include kind-of-sport-color(Triathlon, #a1c4c6);
@include kind-of-sport-color(Mma, #868686);
@include kind-of-sport-color(MortalKombatXL, #a76812);
@include kind-of-sport-color(virtualsports, #8555a1);
@include kind-of-sport-color(virtualdogs, #8555a1);
@include kind-of-sport-color(virtualhorses, #8555a1);
@include kind-of-sport-color(VirtualTennis, #8555a1);
@include kind-of-sport-color(Volleyball, #dac179);
@include kind-of-sport-color(WaterPolo, #338fd6);
@include kind-of-sport-color(Wrestling, #b80f20);
@include kind-of-sport-color(GrecoRomanWrestling, #3A54FB);
@include kind-of-sport-color(X-Sports, #a61f2d);
@include kind-of-sport-color(GlobalOffensive, #c28503);
@include kind-of-sport-color(CounterStrike, #cc581f);
@include kind-of-sport-color(Dota, #5e55a1);
@include kind-of-sport-color(Dota2, #9b1013);
@include kind-of-sport-color(HeroesOfTheStorm, #655e8a);
@include kind-of-sport-color(WorldOfTanks, #464a37);
@include kind-of-sport-color(Hearthstone, #9d7337);
@include kind-of-sport-color(StarCraft, #326d5d);
@include kind-of-sport-color(StarCraft2, #005895);
@include kind-of-sport-color(WorldOfWarcraft, #d09214);
@include kind-of-sport-color(Battlefield, #da6123);
@include kind-of-sport-color(CrossFire, #926b25);
@include kind-of-sport-color(Doom, #be291e);
@include kind-of-sport-color(E-IceHockey, #4196c2);
@include kind-of-sport-color(ETennis, #829d24);
@include kind-of-sport-color(FIFA, #efb724);
@include kind-of-sport-color(AllianceofValiantArms, #847221);
@include kind-of-sport-color(FortiteBattleRoyale, #5e3151);
@include kind-of-sport-color(GearsofWar, #a2191d);
@include kind-of-sport-color(GuildWars2, #c11d1f);
@include kind-of-sport-color(HeroesofNewerth, #76650f);
@include kind-of-sport-color(iRacing, #163885);
@include kind-of-sport-color(KillerInstinct, #00503b);
@include kind-of-sport-color(KingOfGlory, #73588e);
@include kind-of-sport-color(Madden, #9c1417);
@include kind-of-sport-color(MarvelvsCapcom, #1d418c);
@include kind-of-sport-color(NBA2K, #B11419);
@include kind-of-sport-color(Overwatch, #f68720);
@include kind-of-sport-color(Painkiller, #871D1F);
@include kind-of-sport-color(Pokémon, #e8bc08);
@include kind-of-sport-color(ProEvolutionSoccer, #ca171e);
@include kind-of-sport-color(ProjectCARS, #036185);
@include kind-of-sport-color(PUBGPlayerUnknwnsBattleground, #d6751c);
@include kind-of-sport-color(Quake, #744428);
@include kind-of-sport-color(Rainbow_Six_Siege, #754428);
@include kind-of-sport-color(RealSubspaceHockeyLeague, #e56220);
@include kind-of-sport-color(RocketLeague, #04477f);
@include kind-of-sport-color(Smite, #ac7311);
@include kind-of-sport-color(SpecialForce2, #917d1d);
@include kind-of-sport-color(StreetFighter, #c63a20);
@include kind-of-sport-color(StreetFighter5, #c63a20);
@include kind-of-sport-color(SuperSmashBros, #37466f);
@include kind-of-sport-color(TeamFortress2, #9e411a);
@include kind-of-sport-color(Tekken, #bf231b);
@include kind-of-sport-color(Trackmania, #0267a8);
@include kind-of-sport-color(UnrealTournament, #354d94);
@include kind-of-sport-color(Vainglory, #9c5f50);
@include kind-of-sport-color(WarcraftIII, #bb7013);
@include kind-of-sport-color(WarThunder, #b6171c);
@include kind-of-sport-color(Halo, #0a61a5);
@include kind-of-sport-color(RainbowSix, #666d72);
@include kind-of-sport-color(CallOfDuty, #554937);
@include kind-of-sport-color(Weightlifting, #990700);
@include kind-of-sport-color(Oscar, #dac860);
@include kind-of-sport-color(TVShowsAndMovies, #3b6e2b);
@include kind-of-sport-color(SpecialBets, #ef8a00);
@include kind-of-sport-color(Politics, #641f6e);
@include kind-of-sport-color(Go, #e2e2e2);
@include kind-of-sport-color(Kabaddi, #ee6c21);
@include kind-of-sport-color(Yachting, #24537e);
